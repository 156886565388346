<template>
  <div>
    <b-row>
      <b-col
        cols="4"
        class="d-flex flex-column align-items-center justify-content-between"
      >
        <whatsapp-template
          v-if="companyData"
          is-thumbnail
          :primary-color="companyData.primaryColor"
          :url-logo="companyData.urlLogo"
          :system-u-r-l="systemURL"
        />
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="mt-2"
          variant="primary"
          @click="modalShow = !modalShow"
        >
          Pre-visualizar arte para whatsapp
        </b-button>
      </b-col>

      <b-col
        cols="4"
        class="d-flex flex-column align-items-center justify-content-between"
      >
        <print-a-4-template
          v-if="companyData"
          is-thumbnail
          :primary-color="companyData.primaryColor"
          :url-logo="companyData.urlLogo"
          :system-u-r-l="systemURL"
        />

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="mt-2"
          variant="primary"
          @click="modalShow2 = !modalShow2"
        >
          Pre-visualizar arte a4
        </b-button>
      </b-col>
      <b-col cols="4">
        a
      </b-col>

    </b-row>

    <!-- MODAL -->
    <b-modal
      v-model="modalShow"
      scrollable
      title="Banner whatsapp"
      size="lg"
      hide-footer
    >
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="mt-2"
        variant="primary"
        @click="downloadBannerWpp"
      >
        Fazer download
      </b-button>

      <whatsapp-template
        v-if="companyData"
        id="downloaded__whatsapp_banner"
        :primary-color="companyData.primaryColor"
        :url-logo="companyData.urlLogo"
        :system-u-r-l="systemURL"
      />
    </b-modal>

    <!-- MODAL -->
    <b-modal
      v-model="modalShow2"
      scrollable
      title="Banner A4"
      size="lg"
      hide-footer
    >
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="mt-2"
        variant="primary"
        @click="downloadBannerPrintA4"
      >
        Fazer download
      </b-button>

      <print-a-4-template
        v-if="companyData"
        id="downloaded__printa4_banner"
        :primary-color="companyData.primaryColor"
        :url-logo="companyData.urlLogo"
        :system-u-r-l="systemURL"
      />
    </b-modal>

  </div>
</template>
<script>

import {
  BRow, BCol, BModal, BButton,
} from 'bootstrap-vue'

import useJwt from '@/auth/jwt/useJwt'
import jwtDecode from 'jwt-decode'
import Ripple from 'vue-ripple-directive'

import { toJpeg } from 'html-to-image'

import WhatsappTemplate from './templates/WhatsappTemplate.vue'
import PrintA4Template from './templates/PrintA4Template.vue'

export default {
  name: 'PreviewArts',
  components: {
    BRow,
    BCol,
    BModal,
    BButton,
    WhatsappTemplate,
    PrintA4Template,
  },

  directives: {
    Ripple,
  },
  data() {
    return {
      companyData: null,
      modalShow: false,
      modalShow2: false,
    }
  },
  computed: {
    systemURL() {
      return jwtDecode(window.localStorage.getItem('accessToken')).SystemUrl
    },
  },
  mounted() {
    this.getCompanyInfo()
  },
  methods: {
    getCompanyInfo() {
      useJwt.getCompanyInfo()
        .then(response => {
          this.companyData = {
            ...response.data.result,
          }
          return response
        })
    },
    downloadBannerWpp() {
      const target = document.getElementById('downloaded__whatsapp_banner')
      toJpeg(target, {
        quality: 1,
        // backgroundColor: '#fff',
      }).then(dataUrl => {
        const link = document.createElement('a')
        link.download = 'banner-whatsapp.jpeg'
        link.href = dataUrl
        link.click()
        setTimeout(() => {
          this.modalShow = false
        }, 1000)
      })
    },
    downloadBannerPrintA4() {
      const target = document.getElementById('downloaded__printa4_banner')
      toJpeg(target, {
        quality: 1,
        // backgroundColor: '#fff',
      }).then(dataUrl => {
        const link = document.createElement('a')
        link.download = 'banner-print-a4.jpeg'
        link.href = dataUrl
        link.click()
        setTimeout(() => {
          this.modalShow = false
        }, 1000)
      })
    },

  },
}
</script>
